import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Grid } from "@material-ui/core"
import { isMobile } from "react-device-detect"

import "../utils/normalize.css"
import "../utils/css/screen.css"

import { Container } from "../components/styledComponents"
import {
  AboutContainer,
  AboutHeader,
  AboutImage,
  AboutBodyContainer,
  BottomContainer,
  BottomImageLeft,
  BottomImageRight,
} from "../components/Contact/Contact.style"

import AboutSculptureText from "../assets/about/aboutSculpture.png"
import BottomLeftAboutImage from "../assets/about/bottomLeftAbout.png"
import BottomRightAboutImage from "../assets/about/bottomRightAbout.png"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <>
      <Layout title={siteTitle} siteBgColor={"white"}>
        <SEO
          title="Locations"
          keywords={[`wedding`, `sculpture`, `งานแต่งงาน`, `ตู้sculpture`]}
        />
        <Container style={{ paddingTop: "53px" }} isAboutMobile={isMobile}>
          <Grid container spacing={0}>
            <Grid item sm={12} lg={12}>
              <AboutContainer>
                {!isMobile && <AboutHeader>ABOUT US</AboutHeader>}
                <br />
              </AboutContainer>
            </Grid>
            <Grid item sm={12} lg={12}>
              <AboutBodyContainer>
                <AboutImage src={AboutSculptureText} alt="about sculpture" />
              </AboutBodyContainer>
            </Grid>
            <Grid item sm={12} lg={12}>
              <BottomContainer>
                <BottomImageLeft src={BottomLeftAboutImage} />
              </BottomContainer>
            </Grid>
          </Grid>
          <BottomImageRight src={BottomRightAboutImage} />
        </Container>
      </Layout>
    </>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
